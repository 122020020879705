import { PickerInline } from 'filestack-react'
import { useState, useEffect } from 'react'
import { apiKey } from '../apiKey'
import { ReactComponent as CheckIcon } from '../assets/check-icon.svg'

export default function Test() {
	const [popup, setPopup] = useState(false)

	useEffect(() => {
		document.body.style.overflow = popup ? 'hidden' : 'auto'
	}, [popup])

	const onUploadDone = () => {
		setPopup(true)
	}

	const closePopup = () => {
		setPopup(false)
	}

	const pickerOptions = {
		maxFiles: 15,
		disableTransformer: true,
		fromSources: ['local_file_system'],
		accept: ['image/*', 'video/*'],
		customSourcePath: 'test',
		customText: { Filter: 'Search' },
		storeTo: { location: 'dropbox', path: 'Test/' },
	}

	return (
		<main className='divide-y bg-slate-100 font-mont text-slate-800'>
			{popup && (
				<section className='fixed top-0 left-0 z-10 grid h-full w-full place-items-center overflow-hidden bg-black/50'>
					<div className='w-[90%] rounded-xl bg-slate-100 p-7 text-center shadow-lg '>
						<div className='flex items-center justify-center'>
							<div className='pr-2'>
								<CheckIcon className=' fill-slate-700 stroke-slate-700' />
							</div>
							<p className='text-xl font-bold'>Upload Successful</p>
						</div>
						<div className='py-3 pb-5'>
							<p>Thank you for sharing your memories with us.</p>
						</div>
						<div>
							<button
								className='w-full rounded-lg bg-slate-800 py-2 font-bold uppercase text-slate-100'
								onClick={closePopup}
							>
								Done
							</button>
						</div>
					</div>
				</section>
			)}
			<header>
				<div className='py-4'>
					<h1 className='pt-2 text-center font-gv text-6xl text-slate-700'>
						Vivian & Kevin
					</h1>
					<p className='text-center text-base uppercase text-slate-600'>
						Celebrating their wedding day
					</p>
				</div>
			</header>
			<section>
				<div className='flex'>
					<img
						className='aspect-[8/5] object-cover'
						src='https://cdn.pixabay.com/photo/2017/08/06/20/11/wedding-2595862_1280.jpg'
						alt='placeholder'
					/>
				</div>
			</section>
			<section>
				<div className='py-7 px-3 text-center text-base text-slate-600'>
					<p className='pb-3 font-bold uppercase'>Saturday, March 19, 2022</p>
					<p className='font-gv text-3xl'>The Breakers Palm Beach</p>
					<p className='text-base'>1 S County Rd, Palm Beach, FL 33480</p>
				</div>
			</section>
			<section className='px-2 py-3'>
				<div className='px-3 py-6'>
					<p className='text-center text-xl font-bold text-slate-500'>
						Upload your photos & videos to share your memories.
					</p>
				</div>
				<div className='z-0 h-[325px] overflow-hidden rounded-xl shadow-md'>
					<PickerInline
						pickerOptions={pickerOptions}
						apikey={apiKey}
						onUploadDone={onUploadDone}
					/>
				</div>
			</section>
			<section>
				<div className='flex bg-slate-100'>
					<div className='mr-1'>
						<img
							className='object-cover'
							src='https://cdn.pixabay.com/photo/2016/11/22/19/05/couple-1850073_1280.jpg'
							alt='placeholder'
						/>
					</div>
					<div>
						<img
							className='object-cover'
							src='https://cdn.pixabay.com/photo/2019/11/10/11/13/couple-4615557_1280.jpg'
							alt='placeholder'
						/>
					</div>
				</div>
			</section>
			<footer className='pb-2'>
				<div className='border-none bg-slate-100 py-[0.3rem] pb-1 text-center'>
					<p className='text-xs text-slate-500'>
						Powered by:{' '}
						<a
							className='text-slate-600 underline transition-all hover:text-slate-500'
							href='https://kodacreatives.com/'
							target='_blank'
							rel='noreferrer'
						>
							Koda Creative
						</a>
					</p>
				</div>
			</footer>
		</main>
	)
}
