import './App.css'
import Test from './screens/Test'

export default function App() {
	return (
		<>
			<Test />
		</>
	)
}
